<template>
  <li :class="`smart_filters_item user_smart_filters ${isOpenSmartWindow ? 'active_user_filter' : ''}`"
      @click="openSortWindow"
      @mouseenter=" isHover=true "
      @mouseleave=" isHover=false ">
    <div class="smart_filters_icon">
      <img
        src="@/images/svg/icon_filter.svg"
        alt="smart">
    </div>
    <div class="smart_filters_name">
      My filters
    </div>
    <rotate-arrow-icon
      :isRotateIcon="isOpenSmartWindow"
      :styleIcon="2"
      :isHover="isHover"/>
    <ul v-if="isOpenSmartWindow"
        ref="user_smart_list"
        class="smart_filters_list user_filters_list">
      <smart-filter-element
        v-for="(smartFilter, id) in userSmartFilters"
        :key="id"
        :smartFilter="smartFilter"
        :isRemoveHover="isRemoveHover">
        <button
          class="user_filters_list_btn"
          @click.stop="() => deleteSmartFilter(smartFilter.id)"
          @mouseenter=" isRemoveHover=true "
          @mouseleave=" isRemoveHover=false ">
          <img
            src="@/images/svg/icon_clear.svg"
            alt="clear">
        </button>
      </smart-filter-element>
    </ul>
  </li>
</template>

<script>
import SmartFilterElement from '../SmartFilterElement/SmartFilterElement.vue';
import RotateArrowIcon from '@/components/Others/RotateArrowIcon/RotateArrowIcon.vue';

export default {
  name: 'UserSmartFiltersComponent',
  components: {
    SmartFilterElement,
    RotateArrowIcon
  },
  props: {
    userSmartFilters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpenSmartWindow: false,
      isHover: false,
      isRemoveHover: false
    };
  },
  watch: {
    isOpenSmartWindow(value) {
      if (value) {
        window.addEventListener('click', this.eventClick);
      } else {
        this.isFistClick = false;
        window.removeEventListener('click', this.eventClick);
      }
    }
  },
  methods: {
    openSortWindow(event) {
      if (!event.target.closest('.user_filters_list')) {
        this.isOpenSmartWindow = !this.isOpenSmartWindow;
        if (this.isOpenSmartWindow) {
          this.$nextTick(() => {
            const btnBoundRect = event.currentTarget.getBoundingClientRect();
            this.$refs.user_smart_list.style.top = `${btnBoundRect.y + btnBoundRect.height + 6}px`;
          });
        }
      }
    },
    eventClick(e) {
      const target = !e.target.closest('.user_smart_filters');
      if(target) {
        this.isOpenSmartWindow = false;
      }
    },
    deleteSmartFilter(smartFilterID) {
      this.$store.dispatch('DELETE_CUSTOM_FILTER', smartFilterID);
    }
  }
}
</script>

<style scoped lang="scss">
.user_smart_filters {
  position: relative;
  .smart_filters_icon {
    .arrow_image-down {
      transform: rotate(-90deg);
    }
    .arrow_image-up {
      transform: rotate(90deg);
    }
  }
  .user_filters_list {
    position: fixed;
    left: 360px;
    z-index: 99;
    width: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #75757535;
    border-radius: 10px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
    margin-right: 30px;
    cursor: default;
    .user_filters_list_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.3;
      margin-left: 10px;
      img {
        height: 18px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.active_user_filter {
  background-color: #0085FF !important;
  border: 1px solid #0085FF !important;
  & > .smart_filters_name {
    color: #ffffff !important;
  }
  & > .smart_filters_icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(62deg) brightness(115%) contrast(115%) !important;
  }
}
</style>
