<template>
    <li
      :class="{active: imageData.selected}"
      class="image_component"
      :id="imageData.id"
      @click="selectElementForPreview"
      @dragend="onDragend"
      @dragstart="onDragstart($event, imageData)"
      draggable="true"
      v-show="isShowElement">
        <img
          class="image_component_item"
          :src="imageData.image_preview_path"
          :alt="imageData.additional_tags.match(/^[^,]+/)"
          ref="image_component_preview"
          :onLoad="isLoadImage" 
          :onError="imageLoadError"
          v-show="!loadingImage && !notFileImage">
        <NotFilePreviewComponent :isDraggable="true" v-if="notFileImage"/>
        <ImageNavigation
          :imageData="imageData"
          :addButtonAction="addButtonAction"
          draggable="false"
          v-show="!loadingImage"/>
        <LoaderComponent v-if="loadingImage"/>
    </li>
</template>

<script>
import ImageNavigation from './ImageNavigation.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import NotFilePreviewComponent from '@/components/Others/NotFilePreviewComponent/NotFilePreviewComponent.vue';

export default {
    name: 'ImageComponent',
    components: {
        ImageNavigation,
        LoaderComponent,
        NotFilePreviewComponent,
    },
    data: () => {
        return {
            prevIdImage: null,
            loadingImage: false,
            notFileImage: false,
            timer: null,
            isShowElement: false,
            longDownloadTimer: null,
        }
    },
    props: {
        imageData: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        activeImage: {
            type: Function,
            required: true,
        },
        addButtonAction: {
            type: Function,
            required: true,
        },
    },
    methods: {
        onDragstart(e, element) {
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('image',  JSON.stringify(element))

            const img = document.createElement('img');
            img.src = this.imageData.image_preview_path;
            e.dataTransfer.setDragImage(img, 100, 100);
            this.$store.commit('TOGGLE_IS_DRAG_EVENT', true);
        },
        onDragend() {
            this.$store.commit('TOGGLE_IS_DRAG_EVENT', false);
        },
        isLoadImage() {
                clearTimeout(this.timer);
                this.loadingImage = false;
                this.notFileImage = false;
        },
        imageLoadError() {
            if(this.timer) clearTimeout(this.timer);
            this.notFileImage = true;
            this.loadingImage = false;
        },
        selectElementForPreview(event) {
            if(!event.target.closest('.image_component_btn') || event.target.closest('#btn-add-collection')) {
                this.activeImage(this.imageData, this.index);
            }
        },
    },
    watch: {
        imageData(newValue, oldValue) {
            if(newValue.id !== oldValue.id) {
            this.timer = setTimeout(() => {
                this.loadingImage =  true;
            }, 300);
            }
        },
    },
    mounted() {
        this.prevIdImage = this.imageData.image_preview_path;
        this.loadingImage =  true;
        setTimeout(() => {
            this.isShowElement = true;
        }, 10);
        this.longDownloadTimer = setTimeout(() => {
            if(this.loadingImage) {
                this.notFileImage = true;
                this.loadingImage =  false;
                console.error('An error occurred loading the image from the server');
                this.$refs.image_component_preview.src = '';
            }
        }, 5000);
    },
    unmounted() {
        if(this.longDownloadTimer) clearTimeout(this.longDownloadTimer);
    },
};
</script>

<style scoped lang="scss">
    .image_component.active {
        opacity: 0.95;
        outline: 4px solid #0079eb;
    }
    .image_component{
        position: relative;
        overflow: hidden;
        background-color: #EBEFF5;
        border-radius: 4px;
        padding: 8px;
        min-width: 160px;
        max-width: 320px;
        height: 150px;
        max-height: 160px;
        width: auto;
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        z-index: 1;
        outline: 4px solid transparent;
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(69, 69, 69, 0) 0%, rgba(91, 91, 91, 0.5) 100%);
            transition: all .3s ease-out;
            visibility: hidden;
            opacity: 0;
        }
        &:hover:after {
            visibility: visible;
            opacity: 1;
        }
        &:active {
            outline: 4px solid #0079eb;
        }
        &:hover .image_component_nav {
            visibility: visible;
            opacity: 1;
        }
        .image_component_item {
            max-height: 100%;
            max-width: 300px;
        }
    }
</style>
