<template>
  <div class="vertical_resize_line" @mousedown="mouseDown">
  </div>
</template>

<script>
import constants from '@/constants/constants';

export default {
  name: 'VerticalResizeLine',
  data() {
    return {
      isMouseDown: false,
      positionY: 0,
    }
  },
  props: {
    parentElement: {
      type: [Object, null],
      required: true,
    },
    childElement: {
      type: [Object, null],
      required: true,
    },
    looseElement: {
      type: [Object, null],
      required: true,
    },
    isVerticalResizeEnable: {
      type: Object,
      required: false,
    },
    isMainResize: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    mouseDown(event) {
      event.preventDefault();
      this.isMouseDown = true;
      this.positionY = event.screenY;
      this.isVerticalResizeEnable.value = true;
    },
    mouseUp() {
      this.isMouseDown = false;
      this.isVerticalResizeEnable.value = false;
    },
    getMinHeightResizeElement() {
      return this.isMainResize ? constants.MIN_HEIGHT_PREVIEW_ELEMENT : this.parentElement.lastChild.offsetHeight <= constants.MIN_HEIGHT_COLLECT_DISPLAY ?
        constants.MIN_HEIGHT_SELECTED_INFO : constants.MIN_HEIGHT_SELECTED_VIEW;
    },
    isStopResize(distance, height) {
      if(distance < 0) {
        return height - distance === (this.isMainResize ? constants.MIN_HEIGHT_PREVIEW_ELEMENT : constants.MIN_HEIGHT_SELECTED_VIEW);
      }
    },
    resizeComponents(event) {
      if(this.isMouseDown && !!this.childElement) {
        let moveDistance = event.screenY - this.positionY;
        let heightElement = this.childElement.offsetHeight + moveDistance;
        const MIN_HEIGHT_CONTAINER = this.getMinHeightResizeElement();

        let updatedHeightFirstElement = (this.isMainResize ? this.childElement.firstChild.offsetHeight : this.childElement.lastChild.offsetHeight) + moveDistance;      //in child block  - prioritising between children
        if(this.isStopResize(moveDistance, updatedHeightFirstElement)) return;

        let heightSecondElement = this.childElement.lastChild.offsetHeight;
        let heightLooseElement = this.looseElement.offsetHeight - moveDistance;

        if(heightLooseElement <= constants.MIN_HEIGHT_COLLECT_DISPLAY) {
          heightElement = this.childElement.offsetHeight;
          if(this.isMainResize) {
            if(heightSecondElement <= constants.MIN_HEIGHT_SELECTED_VIEW) {
              heightSecondElement = heightSecondElement === constants.MIN_HEIGHT_SELECTED_INFO ? heightSecondElement : constants.MIN_HEIGHT_SELECTED_VIEW;
              updatedHeightFirstElement = this.parentElement.offsetHeight - heightSecondElement - constants.MIN_HEIGHT_COLLECT_DISPLAY - constants.PADDING_COLLECT_DISPLAY;
              heightElement = updatedHeightFirstElement + heightSecondElement;
            } else {
              heightSecondElement = this.childElement.lastChild.offsetHeight - moveDistance;        //preview params
            }
          } else {
            updatedHeightFirstElement = this.childElement.lastChild.offsetHeight;        //descript params
          }
        } else if(updatedHeightFirstElement <= MIN_HEIGHT_CONTAINER) {
          if(this.looseElement.offsetHeight >= constants.MIN_HEIGHT_COLLECT_DISPLAY + constants.MIN_HEIGHT_SELECTED_DIFFER) {
            heightElement = (this.isMainResize ? this.childElement.lastChild.offsetHeight : this.childElement.firstChild.offsetHeight) + MIN_HEIGHT_CONTAINER;
            updatedHeightFirstElement = MIN_HEIGHT_CONTAINER;
          } else {
            return
          }
        }

        if(this.isMainResize) {
          this.childElement.style.gridTemplateRows = `${updatedHeightFirstElement}px ${heightSecondElement}px`;        //preview params
        } else {
          this.childElement.style.gridTemplateRows = `${this.childElement.firstChild.offsetHeight}px ${updatedHeightFirstElement}px`;        //descript params
        }
        this.parentElement.style.gridTemplateRows = `${heightElement}px 1fr`;
        this.positionY = event.screenY;
      }
    },
  },
  mounted() {
    window.addEventListener('mouseup', (e) => {
      this.mouseUp(e);
    });
    window.addEventListener('mousemove', (e) => {
      if(!this.isMouseDown) return;
      this.resizeComponents(e)
    });
  },
};
</script>

<style lang="scss">
  .vertical_resize_line {
    position: absolute;
    bottom: -10px;
    z-index: 2;
    width: 100%;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: row-resize;
  }
</style>
