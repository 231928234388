<template>
    <div class="selected_additionally_info" ref="additionally_info">
        <div class="selected_additionally_list"
            @click="openTagsList"
            @mouseenter=" isHover=true "
            @mouseleave=" isHover=false ">
            <SelectedItemTagTitle
                :iconPath="getIconOfProvider()"
                :providerName="imageData.provider?.name"
                :gameName="imageData.game_name?.name"/>
            <RotateArrowIcon
                :isRotateIcon="isOpenTagsList"
                :styleIcon="0"
                :isHover="isHover"/>
        </div>
        <ul class="selected_items_tags" v-if="isOpenTagsList">
            <SelectedItemTag v-for="tagElement in getFilters()" :tagElement="tagElement"/>
            <li class="selected_item__tag-admin" title="copy ID" @click="copyAdminId">
                Admin ID - {{ imageData.admin_id }}
            </li>
            <SelectedItemCommonInfo :additionalTags="imageData.additional_tags"/>
        </ul>
    </div>
</template>

<script>
import constants from '@/constants/constants';
import SelectedItemTagTitle from './SelectedItemTagTitle/SelectedItemTagTitle.vue';
import SelectedItemCommonInfo from './SelectedItemCommonInfo/SelectedItemCommonInfo.vue';
import SelectedItemTag from './SelectedItemTag/SelectedItemTag.vue';
import RotateArrowIcon from '@/components/Others/RotateArrowIcon/RotateArrowIcon.vue';

const HEIGHT_PADDING_COLLECT_BLOCK = 12;

export default {
    name: "SelectedItemsTags",
    data() {
        return {
            isOpenTagsList: true,
            isHover: false,
            prevHeight: null,
            containerHeight: 0,
            asideContainerElements: null,
        }
    },
    components: {
        SelectedItemTagTitle,
        SelectedItemCommonInfo,
        SelectedItemTag,
        RotateArrowIcon,
    },
    props: {
        imageData: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async copyAdminId() {
            const idForCopy = this.imageData.admin_id.toString();
            const fakeInput = document.createElement('textarea');
            fakeInput.style.position = "absolute";
            fakeInput.style.left = "-9999px"; // Убираем элемент за пределы экрана
            fakeInput.value = idForCopy;
            document.body.appendChild(fakeInput);
            fakeInput.select();
            try {
                const successful = document.execCommand("copy");
                if (!successful) {
                    console.error("Failed to copy the text.")
                }
            } catch (err) {
                console.error("Copy Error:", err);
            }
            document.body.removeChild(fakeInput);
        },
        findAsideParentContainer() {
            const asideBlock = document.getElementById('aside_directory_block');
            const firstGridBlock = asideBlock.firstChild;
            this.asideContainerElements = {
                asideParent: asideBlock,
                firstGridElement: firstGridBlock,
                wrapperPreview: firstGridBlock.firstChild,
                wrapperDescript: firstGridBlock.lastChild,
            };
            return this.asideContainerElements;
        },
        updateSizeAsidePanel(asideParent, firstGridElement, wrapperPreview, wrapperDescript) {
            let heighPreviewBlock;
            let previewDisplayHeight;
            let previewInfoHeight;

            if(!this.isOpenTagsList) {      //close window
                this.prevHeight = wrapperDescript.offsetHeight;
                previewDisplayHeight = wrapperPreview.offsetHeight;
                previewInfoHeight = constants.MIN_HEIGHT_SELECTED_INFO;
                heighPreviewBlock = previewDisplayHeight + previewInfoHeight;
            } else {        //open window
                if(asideParent.lastChild.offsetHeight === constants.MIN_HEIGHT_COLLECT_DISPLAY) {        //MIN HEIGHT COLLECT
                    previewDisplayHeight = firstGridElement.offsetHeight - this.prevHeight;
                    previewInfoHeight = this.prevHeight;
                    heighPreviewBlock = firstGridElement.offsetHeight;
                } else {        //others points
                    const limitHeight = asideParent.offsetHeight - (wrapperPreview.offsetHeight + this.prevHeight);
                    if(limitHeight < constants.MIN_HEIGHT_COLLECT_DISPLAY) {            //if height info block is greater than the height collect block
                        this.prevHeight = this.prevHeight - (constants.MIN_HEIGHT_COLLECT_DISPLAY - limitHeight + HEIGHT_PADDING_COLLECT_BLOCK);
                    }
                    if(this.prevHeight < constants.MIN_HEIGHT_SELECTED_VIEW) {
                        previewDisplayHeight = wrapperPreview.offsetHeight - (constants.MIN_HEIGHT_SELECTED_VIEW - this.prevHeight);
                        previewInfoHeight = constants.MIN_HEIGHT_SELECTED_VIEW;
                    } else {
                        previewDisplayHeight = wrapperPreview.offsetHeight;
                        previewInfoHeight = this.prevHeight;
                    }
                    heighPreviewBlock = wrapperPreview.offsetHeight + this.prevHeight;
                }
            }
            asideParent.style.gridTemplateRows = `${heighPreviewBlock}px 1fr`;
            firstGridElement.style.gridTemplateRows = `${previewDisplayHeight}px ${previewInfoHeight}px`;
        },
        openTagsList() {
            const {asideParent, firstGridElement, wrapperPreview, wrapperDescript} = this.asideContainerElements ? this.asideContainerElements : this.findAsideParentContainer();
            this.isOpenTagsList = !this.isOpenTagsList;
            this.updateSizeAsidePanel(asideParent, firstGridElement, wrapperPreview, wrapperDescript);
        },
        getFilters() {
            const arrTags = this.imageData.tags;
            arrTags.forEach(tag => {
                tag.type = constants.TAG_ELEMENT;
            });
            const elemProvider = {
                provider: true,
                id: this.imageData.provider.id,
                name: this.imageData.provider.name,
            };
            const objectGame = this.imageData.game_name;
            objectGame.type = constants.GAMES;
            const arrFilters = this.imageData.filter_items;
            const arr = [elemProvider, objectGame, ...arrFilters, ...arrTags ];
            return arr;
        },
        getIconOfProvider() {
            return this.$store.getters.PROVIDER_BY_ID(this.imageData.provider.id)['icon_path'];
        },
    },
    watch: {
        containerHeight(newHeight) {
            if(this.asideContainerElements?.asideParent.lastChild.offsetHeight < constants.MIN_HEIGHT_COLLECT_DISPLAY) return;
            if(newHeight >= constants.MIN_HEIGHT_SELECTED_WINDOW_TAGS && !this.isOpenTagsList) {
                this.isOpenTagsList = !this.isOpenTagsList;
            }
        },
    },
    mounted(){
        const infoContainer = this.$refs.additionally_info;
        this.resizeObserver = new ResizeObserver((entries) => {     //init ResizeObserver
            for (let entry of entries) {
                this.containerHeight = entry.contentRect.height;
            }
        });
        this.resizeObserver.observe(infoContainer);     //watch the container height change
    },
    beforeUnmount() {
        if(this.resizeObserver) {
            this.resizeObserver.disconnect();       //clear ResizeObserver
        }
    },
}
</script>

<style lang="scss">
    .selected_additionally_info {
        width: 100%;
        height: 100%;
        padding-right: 8px;
        .selected_additionally_list {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }
        .selected_items_tags {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            padding-top: 10px;
            margin-right: 5px;
            gap: 8px;
            list-style: none;
            overflow-y: hidden;
            .selected_item__tag-admin {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                border-radius: 20px;
                border: 1px solid #757575;
                padding: 8px 14px;
                list-style: none;
                margin: 0;
                box-sizing: border-box;
                cursor: pointer;
            }
        }
    }
</style>
