<template>
      <div class="preview_image_scale" v-if="imageSize">
          {{ imageSize + '%'}}
      </div>
</template>

<script>

export default {
  name: "SizeInfoComponent",
  components: {
  },
  props: {
    imageSize: {
      type: [Number, null],
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.preview_image_scale {
    position: absolute;
    bottom: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 32px;
    background-color: #EBEFF5;
    border-radius: 5px;
}
</style>
