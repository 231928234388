<template>
    <div class="image_component_nav">
        <button class="image_component_btn image_component_btn-add" @click="addToPreCollect">
            <img src="@/images/svg/icon_add.svg" alt="">
        </button>
        <button class="image_component_btn image_component_btn-collection" id="btn-add-collection" @click="openAddToCollectPopup" v-if="isCollectionView">
            <img src="@/images/svg/icon_collect-add.svg" alt="">
        </button>
        <button class="image_component_btn image_component_btn-remove" @click="deleteElementFromCollecttion" v-else>
            <img src="@/images/svg/icon_clear.svg" alt="">
        </button>
        <div class="image_component_btn image_component_btn-download" @click="downloadFile">
            <img src="@/images/svg/icon_download.svg" alt="">
        </div>
    </div>
</template>

<script>
import constants from '@/constants/constants';

export default {
    name: 'ImageNavigation',
    props: {
        imageData : {
            type: Object,
            required: true,
        },
        addButtonAction: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isCollectionView: !this.$route.params.collection_name,
        }
    },
    methods: {
        downloadFile() {
            this.$store.dispatch('DOWNLOAD_FILE', this.imageData.id).then((res) =>{
                if(res.status === constants.STATUS_CODE.OK) {
                    const link = document.createElement('a');
                    link.href = res.data;
                    link.click();
                    link.remove();
                }
            }).catch((err) => {
                console.error(`${err.name}: An error occurred while uploading the file`);
            })
        },
        openAddToCollectPopup() {
            this.$store.commit('CHANGE_VIEW_COLLECTION_POPUP', {isOpen: true, addOnlyOneElement: true});
        },
        addToPreCollect() {
            this.addButtonAction(this.imageData);
        },
        deleteElementFromCollecttion() {
            const collectionId = this.$route.params.collection_name.split('=')[1];
            this.$store.dispatch('REMOVE_FILE_FROM_COLLECTION', [+collectionId, this.imageData.id]);
        },
    },
};
</script>

<style scoped lang="scss">
    .image_component_nav {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        z-index: 2;
        transition: all .3s ease-out;
        visibility: hidden;
        opacity: 0;
        .image_component_btn {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background-color: #fff;
            cursor: pointer;
            img {
                width:  16px;
                height: 16px;
            }
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
                }
            }
        }
        .image_component_btn-collection,
        .image_component_btn-remove,
        .image_component_btn-download {
            img {
                width:  24px;
                height: 24px;
            }
        }
        .image_component_btn-remove {
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(37%) sepia(38%) saturate(6479%) hue-rotate(343deg) brightness(98%) contrast(106%);
                }
            }
        }
    }
</style>