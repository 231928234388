<template>
    <div class="filters_panel">
        <div class="filters_panel_header">
            <div class="filters_panel_header-title">
                <img src="@/images/svg/icon_filter.svg" alt="">
                Filters
            </div>
            <TogglePanelFilter @click="isOpenFilterPanel" :filterPanel="filterPanel"/>
        </div>
        <div class="filters_panel_types" v-if="getDataFilters.length && !isGetDataFilters">
          <FilterTypeComponent v-for="filterType in allFilters"
            :filterType="filterType"
            />
          <FilterHQComponent/>
        </div>
        <ButtonSaveToSmartFilter v-if="getDataFilters.length && !isGetDataFilters"/>
        <LoaderComponent v-else-if="!isBadRequestFilters"/>
        <div class="app_version">v.1.3.12 (82)</div>
    </div>
</template>

<script>
import TogglePanelFilter from './TogglePanelFilter/TogglePanelFilter.vue';
import FilterTypeComponent from './FilterTypeComponent/FilterTypeComponent.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import FilterHQComponent from './FilterTypeComponent/CommonFilterElementsComponent/FilterHQComponent/FilterHQComponent.vue';
import ButtonSaveToSmartFilter from './ButtonSaveToSmartFilter/ButtonSaveToSmartFilter.vue';

export default {
  name: 'FilterPanelComponent',
  data() {
    return {
      allFilters: [],
    }
  },
  components: {
    TogglePanelFilter,
    FilterTypeComponent,
    FilterHQComponent,
    LoaderComponent,
    ButtonSaveToSmartFilter,
  },
  props: {
    filterPanel: {
        type: Object,
        required: true,
    },
    isOpenFilterPanel: {
        type: Function,
        required: true,
    }
  },
  computed: {
    getDataFilters() {
      const filtersData = this.$store.getters.DATA_FILTERS;
      const providerData = this.$store.getters.DATA_PROVIDERS;
      const gamesData = this.$store.getters.DATA_GAMES;
      const tagsData = this.$store.getters.DATA_TAGS;

      if(!tagsData.tagsList.length) {
        this.allFilters = [...providerData.fileList, ...gamesData.fileList, ...filtersData, ...tagsData.tagsList];
      } else {
        this.allFilters = [providerData, gamesData, ...filtersData, tagsData];
      }
      return this.allFilters;
    },
    isGetDataFilters() {
      const isGetFilters = this.$store.getters.IS_FILTERS_DATA;
      return Object.values(isGetFilters).filter(filter => filter).length;
    },
    isBadRequestFilters() {
      const isBadRequest = this.$store.getters.IS_BAD_REQUEST_FILTERS;
      this.filterPanel.isOpen = Object.values(isBadRequest).filter(filter => filter).length !== Object.keys(isBadRequest).length;
      return Object.values(isBadRequest).filter(filter => filter).length === Object.keys(isBadRequest).length;
    },
  },
};
</script>

<style lang="scss">
    .filters_panel {
      max-height: calc(100vh - 88px); //80px
      min-width: 292px;
      width: 292px;
      padding: 0px 5px 0px 10px;
      border-right: 1px solid #EBEFF5;
      display: flex;
      flex-direction: column;
      .filters_panel_header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding-right: 10px;
        padding-bottom: 20px; //was add
        .filters_panel_header-title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 18px;
            font-weight: 700;
            img {
                width: 18px;
            }
        }
      }
      .filters_panel_types {
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: 15px;
        width: 100%;
        gap: 14px 0;
        height: 100%;
        padding-right: 10px;
        padding-bottom: 10px;
        overflow-y: auto;
        overflow-x: visible;
        scrollbar-gutter: stable;
      }
      .app_version {
        position: absolute;
        bottom: 2px;
        color: #757575;
        display: inline-flex;
        align-items: flex-end;
        padding-top: 3px;
      }
    }
</style>