<template>
    <aside class="aside_directory" id="aside_directory_block" ref="aside_wrapper">
        <div class="preview_wrapper" ref="first_grid_template">
            <div class="aside_directory_first" id="selected_image_container_id">
                <SelectedImageView
                    :openFullSizeImage="openFullSizeImage"
                    :getImageForAside="getImageForAside"/>
                <VerticalResizeLine
                    class="element_preview_resize"
                    :parentElement="asideContainer"
                    :childElement="firstGridTemplate"
                    :looseElement="collectElement"
                    :isVerticalResizeEnable="isVerticalResizeEnable"
                    :isMainResize="true"
                    v-if="getImageForAside?.id"/>
            </div>
            <div class="aside_directory_second" id="selected_desc_container_id" v-if="getImageForAside?.id">
                <SelectedImageDescription
                  :addButtonAction="functionDropElementToArea"
                  :imageData="getImageForAside"/>
                <VerticalResizeLine
                    :parentElement="asideContainer"
                    :childElement="firstGridTemplate"
                    :looseElement="collectElement"
                    :isVerticalResizeEnable="isVerticalResizeEnable"/>
            </div>
        </div>
        <SavedFilesBasketComponent
            ref="collect_images_component"
            :storeDropArea="storeDropArea"
            :isVerticalResizeEnable="isVerticalResizeEnable"
            :functionDropElementToArea="functionDropElementToArea"
            :removeElementFromPrecollect="removeElementFromPrecollect"
            :addElementToPreviewFunction="addElementToPreviewFunction"/>
    </aside>
    <FullSizePopupComponent 
        v-if="isFullPopUp"
        :viewElement="getImageForAside"
        :functionClose="openFullSizeImage"/>
</template>

<script>
import SavedFilesBasketComponent from './SavedFilesBasketComponent/SavedFilesBasketComponent.vue';
import VerticalResizeLine from '@/components/Others/resizer/VerticalResizeLine.vue';
import SelectedImageView from './SelectedImageContainer/SelectedImageView/SelectedImageView.vue';
import FullSizePopupComponent from '@/components/Others/FullSizePopupComponent/FullSizePopupComponent.vue';
import SelectedImageDescription from './SelectedImageContainer/SelectedImageDescription/SelectedImageDescription.vue';

export default {
    name: "AsideComponent",
    components: {
        SavedFilesBasketComponent,
        VerticalResizeLine,
        SelectedImageView,
        FullSizePopupComponent,
        SelectedImageDescription,
    },
    props: {
        getImageForAside: {
            type: [Object, null],
            required: true,
        },
        addElementToPreviewFunction: {
            type: Function,
            requred: true,
        },
    },
    data() {
        return {
            isVerticalResizeEnable: {
                value: false,
            },
            storeDropArea: this.$store.getters.DATA_PRECOLLECT,
            isFullPopUp: false,
            asideContainer: null,
            firstGridTemplate: null,
            collectElement: null,
        }
    },
    methods: {
        functionDropElementToArea(element) {
            this.$store.commit('ADD_ELEMENT_PRECOLLECT_DATA', element);
        },
        removeElementFromPrecollect(index) {
            this.$store.commit('REMOVE_ELEMENT_PRECOLLECT', index);
        },
        openFullSizeImage() {
            if(!this.getImageForAside?.image_path) return;
            this.isFullPopUp = !this.isFullPopUp;
        },
        initSizeForAsideBlock() {
            this.asideContainer = this.$refs.aside_wrapper;
            this.firstGridTemplate = this.$refs.first_grid_template;
            this.collectElement = this.$refs.collect_images_component.$el;
            this.asideContainer.style.gridTemplateRows = `${this.firstGridTemplate.offsetHeight + 'px'} 1fr`;
        },
    },
    mounted() {
        this.initSizeForAsideBlock();
    },
}
</script>

<style scoped lang="scss">
    .aside_directory {
        ::-webkit-scrollbar {
            background-color: #ffffff !important;
            width: 5px;
            height: 5px;
        }
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 2fr 1fr;
        align-items: stretch;
        gap: 10px;
        margin-top: 10px;
        .preview_wrapper {
            display: grid;
            grid-template-rows: 50% 50%;
            max-height: 100%;
            & > div:only-child {
                grid-row: span 2;
                border-radius: 10px;
            }
            .aside_directory_first {
                position: relative;
                background: #EBEFF5;
                border-radius: 10px 10px 0 0;
                .element_preview_resize {
                    height: 5px;
                    bottom: 0px;
                }
            }
            .aside_directory_second {
                position: relative;
                height: 100%;
                background: #EBEFF5;
                border-radius: 0 0 10px 10px;
            }
        }
    }
</style>
