<template>
    <div class="collect__images__list"
      ref="collectList"
      @drop="onDrop($event)"
      @dragover.prevent
      @dragenter.prevent>
        <div :class="{'droparea_not_empty': dataCollect?.length && isViewPrecollectElements, 'droparea_hide': (dataCollect?.length > 10 || (isMinHeight && dataCollect?.length)) && isViewElements, 'collect__images__droparea': true, 'drug_drop_active': drugEvent.isOn}">
            <div class="collect_image_description">
                <img src="@/images/svg/icon_dragndrop_area.svg" alt="Rating"/>
                Drag here to collect and download
            </div>
        </div>
        <div class="collect_image_items" v-if="dataCollect?.length && isViewElements" id="collect_images_id">
            <CollectImageComponent
              v-for="(image, index) in dataCollect"
              :image="image"
              :index="index"
              :removeOneElementFunction="removeOneElementFunction"
              :addElementToPreviewFunction="addElementToPreviewFunction"/>
        </div>
    </div>
</template>

<script>
import constants from '@/constants/constants';
import CollectImageComponent from './CollectImageComponent.vue';

export default {
    name: "CollectDropareaComponent",
    components: {
        CollectImageComponent,
    },
    props: {
        storeDropArea: {
            type: Object,
            required: true,
        },
        isEmptyCollectData: {
            type: Function,
            required: true,
        },
        isVerticalResizeEnable: {
            type: Object,
            required: false,
        },
        removeOneElementFunction: {
            type: Function,
            requred: true,
        },
        addElementToPreviewFunction: {
            type: Function,
            requred: true,
        },
        functionDropElementToArea: {
            type: Function,
            requred: true,
        },
    },
    data () {
        return {
            dataCollect: this.storeDropArea,
            drugEvent: this.$store.getters.IS_DRAG_EVENT,
            heightCollectList: null,
            isMinHeight: false,
            isViewElements: false,
        }
    },
    watch: {
        dataCollect: {
            handler(newValue) {
                this.isEmptyCollectData(newValue.length > 0);
                this.heightCollectList = this.$refs.collectList.offsetHeight;
                this.isMinHeight = constants.MIN_HEIGHT_DROPAREA >= this.heightCollectList;
            },
            deep: true,
        },
        heightCollectList(newValue) {
            this.isMinHeight = (constants.MIN_HEIGHT_DROPAREA >= newValue) && this.dataCollect?.length;
        },
    },
    methods: {
        onDrop(event) {
            const element = event.dataTransfer.getData('image');
            if(!!element) {
                this.functionDropElementToArea(JSON.parse(event.dataTransfer.getData('image')));
            }
        },
        resizeComponents() {
            this.heightCollectList = this.$refs.collectList?.offsetHeight;
        },
    },
    computed: {
        isViewPrecollectElements() { //эта функция универсальная ее можно оставить как есть, необходима просто для того чтобы отображение было после полной загрузки
            this.isViewElements = this.$store.getters.IS_VIEW_PRECOLLECT;
            return this.isViewElements;
        },
    },
    mounted() {
        this.heightCollectList = this.$refs.collectList.offsetHeight;
        this.isEmptyCollectData(this.dataCollect?.length > 0);
        
        window.addEventListener('mousemove', (e) => {
            if(!this.isVerticalResizeEnable.value) return;
            this.resizeComponents(e);
        });
    },
}
</script>

<style scoped lang="scss">
    .collect__images__list {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        .collect__images__droparea {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            text-align: center;
            color: #757575;
            margin-bottom: 10px;
            border: 2px dashed rgba(176, 181, 193, 0.5);
            border-radius: 10px;
            padding: 5px;
            height: 100%;
            opacity: 1;
            transition: .4s;
            .collect_image_description {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 8px;
            }
        }
        .drug_drop_active {
            border: 2px dashed #0085FF;
            background-color: rgba(255, 255, 255, 0.95);
        }
        .droparea_not_empty {
            height: 125px;
        }
        .droparea_hide {
            display: none;
            opacity: 0;
        }
        .droparea_hide.drug_drop_active {
            display: flex;
            opacity: 1;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            z-index: 10;
            background-color: rgba(255, 255, 255, 0.85);
            animation: view 0.3s linear;
            @keyframes view {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
            }
        }
        .collect_image_items {
            min-height: 76px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            overflow-y: auto;
        }
    }
    
</style>
